/* Set the border radius of the input boxes to 0 */
input[type="text"],
input[type="password"],
input[type="email"] {
  border-radius: 0 !important;
}

/* Change the highlighting color to black */
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  border-color: black !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important;
}

/* Change the SignIn button background color to black */
button[type="submit"] {
  background-color: black !important;
  border-color: black !important;
}

/* Change the SignIn button background color on hover to a slightly lighter shade of black */
button[type="submit"]:hover {
  background-color: #222 !important;
  border-color: #222 !important;
}

/* Remove border radius from buttons */
button {
  border-radius: 0 !important;
}

/* Set the background color and border color of the selection button to black */
[data-amplify-accordion-item] button {
  background-color: black !important;
  border-color: black !important;
}

/* Set the background color and border color of the selection button on hover */
[data-amplify-accordion-item] button:hover {
  background-color: #222 !important;
  border-color: #222 !important;
}

/* Change the "Create Account" and "Forgot Password" link color to black */
[data-amplify-authenticator] a {
  color: black !important;
}

/* Change the color of the show password icon when focused */
[data-amplify-authenticator] button:focus svg {
  color: black !important;
}

/* Change the color of the show password icon when hovered */
[data-amplify-authenticator] button:hover svg {
  color: black !important;
}

/* Add this to your signin.css */

/* Change the "Create Account" and "Forgot Password" link color to black */
[data-amplify-authenticator] a,
[data-amplify-authenticator] a[data-amplify-link] {
  color: black !important;
}

/* Remove the hover color for the "Forgot Password" link */
[data-amplify-authenticator] a[data-amplify-link]:hover {
  color: black !important;
}

/* Change the color of the active tab to black */
[data-amplify-authenticator] button[aria-selected="true"] {
  background-color: black !important;
  border-color: black !important;
  color: white;
}

/* Change the color of the inactive tab to black when hovered */
[data-amplify-authenticator] button[aria-selected="false"]:hover {
  background-color: #222 !important;
  border-color: #222 !important;
  color: white;
}

/* Change the "Forgot Password" link color to black */
.amplify-button--link {
  color: black !important;
}

/* Remove the hover color for the "Forgot Password" link */
.amplify-button--link:hover {
  color: black !important;
  background-color: white !important;
}