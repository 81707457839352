.hero {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  position: relative;
}

.background-image {
  background: url('https://res.cloudinary.com/dhygjkoey/image/upload/v1681578171/youdesignai/websiteAssets/frontCover.png') center center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
  
  .hero h1 {
    color: black;
    font-size: 3rem;
    margin-top: 30px;
    font-weight: 800 !important;
  }
  
  .options {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
  }
  
  .option {
    text-align: center;
    font-size: 1.5rem;
  }

  #mainTitle {
    background-color: white;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 25px;
    padding: 10px;
  }
  @media (max-width: 575.98px) {
    .hide-on-mobile {
      display: none;
    }
  }