.design-shirt-header {
  background-color: #fff;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -70px;
}

.option-card {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
}

.option-card.selected {
  border: 2px solid black;
}

.option-card:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.option-card .overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}

.option-card .overlay h2 {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.option-card.selected .overlay h2 {
  color: #000;
  background-color: white;
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}