.generated-designs-header {
    background-color: #fff;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .generated-designs-card {
    border: none;
  }
  
  .generated-designs-card img {
    object-fit: cover;
  }
  
  .generated-designs-card .btn-primary {
    width: 100%;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .generated-designs-card .btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
  