
.color_buttons {
  background-color: transparent !important;
}

.colorCircle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.colorCircle.colorWhite {
  border: 1px solid black;
}

.sizeActive {
  background-color: rgb(225, 225, 225) !important;
  color: black !important;
  border: 1px solid black !important;
}

.size_buttons {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

.color_btn {
  margin-right: -15px !important;
}
