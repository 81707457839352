.btn {
  border-radius: 0 !important;
  border-width: 0 !important;
  background-color: black ;
  color: white ;
}

.button_background {
  background-color: black !important;
}

body, html {
  overflow-x: hidden;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif !important;
}

.main-content {
  min-height: calc(100vh - 56px - 3rem); /* Adjust the height according to your navbar and footer heights */
  display: flex;
  flex-direction: column;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

#boldFont {
  font-weight: bold;
}