
  .prompt h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .prompt label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  #formBasicPrompt:focus {
      border-color: black !important;
      box-shadow: none !important;
  }
  
  .prompt input[type="text"] {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid black;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .prompt button {
    margin-top: 1rem;
    background-color: black !important;
  }
  
.alert .close {
  position: relative;
  top: -2px;
  right: -2px;
}