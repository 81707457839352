.navbar a {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

.white-link {
  color: white;
}

.large-icon {
  font-size: 24px;
}

.navbar-dark .navbar-toggler:focus {
  box-shadow: none;
}

#navbar-toggle .navbar-toggler-icon {
  color: white !important;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30' fill='none' stroke='%23fff'%3e%3cpath stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-navbar {
  padding-bottom: 0px !important;
}

@media (max-width: 575.98px) {
  .custom-navbar .navbar-brand {
    align-items: center;
    padding-right: 35px;
  }
}