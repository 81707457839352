.about-text {
  text-align: left;
  font-size: 14px;
  margin-bottom: 50px;
}

.bold {
  font-weight: bold;
}

.about-header {
  background: url('https://res.cloudinary.com/dhygjkoey/image/upload/v1681578171/youdesignai/websiteAssets/frontCover.png') center center;
  background-position: center;
  background-size: cover;
  height: 400px; /* Adjust this value to control the height of the background image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-footer {
  background: url('https://res.cloudinary.com/dhygjkoey/image/upload/v1681578460/youdesignai/websiteAssets/about.png') center center;
  background-position: center;
  background-size: cover;
  height: 400px; /* Adjust this value to control the height of the background image */
  display: flex;
  justify-content: center;
  align-items: center;
}
  