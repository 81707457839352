.account-page {
    text-align: center;
    margin-top: 2rem;
  }
  
  .account-page h2 {
    margin-bottom: 2rem;
  }
  
  .account-page .design-card {
    border: none;
    border-radius: 0;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .account-page button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .account-page .close {
    position: relative;
    top: -2px;
    right: -2px;
  }

  .my-designs-header {
    background-color: #fff;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -70px;
  }
  
  .custom-pagination {
    justify-content: flex-end;
  }
  
  .custom-pagination .page-item {
    border: none;
  }
  
  .custom-pagination .page-item .page-link {
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 0 !important;
  }
  
  .custom-pagination .page-item.active .page-link {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 0 !important;
  }
  